import clsx from 'clsx';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React, { HTMLAttributes } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import LiveHeader from '../LiveHeader';

export interface PageProps {
	/**
	 * SEO Page title.
	 */
	title: string;
	/**
	 * SEO description.
	 */
	description?: string;

	live?: boolean;
	// Banner is a full width element the comes before the body and after the nav bar
	banner?: React.ReactChild | React.ReactChildren;

	containerProps?: HTMLAttributes<HTMLDivElement>;
}

const Page: React.FC<PageProps> = ({
	title,
	description,
	live = false,
	banner,
	children,
	containerProps,
}) => {
	return (
		<>
			<GatsbySeo title={title} description={description} />

			{live ? (
				<LiveHeader />
			) : (
				<div className="mx-auto max-w-7xl">
					<Header />
				</div>
			)}

			{banner}

			<div
				{...containerProps}
				className={clsx('mx-auto max-w-7xl px-3 xl:px-0', containerProps?.className)}
			>
				{children}
				<Footer />
			</div>
		</>
	);
};

export default Page;
