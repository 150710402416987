import Countdown from '@components/Countdown';
import Link from '@components/Link';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { currentEvent } from '../../../utils/event';

export interface LiveHeaderProps {}

const LiveHeader: React.FC<LiveHeaderProps> = () => {
	const data = useStaticQuery(graphql`
		{
			eventsData: allEventsYaml {
				nodes {
					startDateTime
					endDateTime
				}
			}
		}
	`);
	const event = currentEvent(
		data.eventsData.nodes.filter((event) => event.startDateTime && event.endDateTime),
	);
	// TODO:
	const [open, setOpen] = useState(false);
	return (
		<header
			className="mb-5 sticky top-0 left-0 right-0 px-2 py-3 bg-secondary-500"
			style={{
				zIndex: 5,
			}}
		>
			{/* Mobile Nav */}
			<div
				className="ml-2 py-3 md:hidden cursor-pointer"
				color="textDark"
				onClick={() => setOpen(true)}
				aria-expanded={open}
				role="button"
				tabIndex={0}
				aria-label="Mobile navigation trigger"
				aria-controls="mobile-nav-links"
			>
				<FaBars />
			</div>
			{/* Items */}
			<div
				className="md:hidden py-2 px-3 text-2xl space-y-4 flex flex-col bg-secondary-500 fixed top-0 left-0 bottom-0"
				id="mobile-nav-links"
				style={{
					width: 300,
					transform: open ? 'translateX(0%)' : 'translateX(-105%)',
					transition: 'transform 200ms ease-in-out',
					zIndex: 1,
				}}
				aria-hidden={!open}
				role="navigation"
			>
				<Link className="text-white font-bold" to="/live/" onClick={() => setOpen(false)}>
					Home
				</Link>
				<Link className="text-white font-bold" to="/live/events" onClick={() => setOpen(false)}>
					Events
				</Link>
				<Link className="text-white font-bold" to="/live/rules" onClick={() => setOpen(false)}>
					Rules
				</Link>
				<Link className="text-white font-bold" to="/live/prizes" onClick={() => setOpen(false)}>
					Prizes
				</Link>
				<Link className="text-white font-bold" to="/live/schedule" onClick={() => setOpen(false)}>
					Schedule
				</Link>
				<Link className="text-white font-bold" to="/" onClick={() => setOpen(false)}>
					Main Site
				</Link>
			</div>
			{/* Mobile nav overlay (dark part) */}
			<div
				className={clsx('fixed inset-0', 'md:hidden', !open && 'hidden')}
				style={{
					backgroundColor: 'rgba(0,0,0,.5)',
				}}
				onClick={() => setOpen(false)}
			/>

			{/* Desktop nav */}
			<div className="py-1 hidden md:block" role="navigation">
				<Link className="text-white font-bold p-2" to="/live/">
					Home
				</Link>
				<Link className="text-white font-bold p-2" to="/live/events">
					Events
				</Link>
				<Link className="text-white font-bold p-2" to="/live/rules">
					Rules
				</Link>
				<Link className="text-white font-bold p-2" to="/live/prizes">
					Prizes
				</Link>
				<Link className="text-white font-bold p-2" to="/live/schedule">
					Schedule
				</Link>
				<Link className="text-white font-bold p-2" to="/">
					Main Site
				</Link>
			</div>

			{/* <Box mx="auto" /> */}
			<div
				className="text-white bg-primary-500 absolute px-4 flex"
				style={{
					left: '50%',
					transform: 'translateX(-50%)',
					top: 0,
					bottom: -10,
					boxShadow:
						'0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)',
				}}
			>
				<div className="m-auto text-xl sm:text-2xl">
					{event ? <Countdown start={event.start} end={event.end} /> : '00:00'}
				</div>
			</div>
		</header>
	);
};

export default LiveHeader;
