import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

function twoDigits(number) {
	const numStr = number.toString();
	if (number < 0) {
		return '00';
	} else if (numStr.length <= 1) {
		return `0${number}`;
	}
	return numStr;
}

interface Props {
	start: DateTime;
	end?: DateTime;
}

function timeLeft({
	currentTime,
	start,
	end,
}: {
	currentTime: DateTime;
	start: DateTime;
	end?: DateTime;
}): string {
	let time: DateTime;
	if (start.diff(currentTime).toMillis() > 0) time = start;
	else if (end.diff(currentTime).toMillis() > 0) time = end;
	else return '00:00:00';
	const diff = time.diffNow(['days', 'hours', 'minutes', 'seconds', 'milliseconds']);

	const { days, hours, minutes, seconds } = diff;

	const timeString = `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(seconds)}`;

	if (days === 1) {
		return `1 day ${timeString}`;
	} else if (days > 1) {
		return `${days} days ${timeString}`;
	}

	return timeString;
}

export interface CountdownProps {
	start: DateTime;
	end?: DateTime;
}

const Countdown: React.FC<CountdownProps> = ({ start, end }) => {
	const [time, setTime] = useState(DateTime.now());
	useEffect(() => {
		// update time every second
		const timer = setInterval(() => {
			setTime(DateTime.now());
		}, 1000);
		// remove interval when the timer component is unmounted
		return () => clearInterval(timer);
	}, []);

	const left = timeLeft({ currentTime: time, start, end });

	return (
		<span aria-live="off" role="timer">
			{left}
		</span>
	);
};

export default Countdown;
