import { DateTime } from 'luxon';

type EventData<T> = T & {
	// 2020-09-28 1:00 PM
	startDateTime: string;
	endDateTime: string;
};

interface ParsedDates {
	start: DateTime;
	end: DateTime;
}

export function currentEvent<T>(
	events: EventData<T & ParsedDates>[],
): EventData<T & ParsedDates> | undefined {
	if (events.length === 0) return undefined;

	const eventWithTimes: EventData<T & ParsedDates>[] = events.map((event) => ({
		...event,
		start: DateTime.fromISO(event.startDateTime),
		end: DateTime.fromISO(event.endDateTime),
	}));

	const duringEvent = eventWithTimes
		.filter(({ start, end }) => {
			const now = DateTime.now();
			return 0 <= now.diff(start).toMillis() && end.diff(now).toMillis() >= 0;
		})
		.sort(({ end: e1 }, { end: e2 }) => e2.diff(e1).toMillis());

	console.log({ duringEvent });

	if (duringEvent.length > 0) return duringEvent[0];

	// default
	const sorted = eventWithTimes.sort((a, b) => a.start.diff(b.start).toMillis());
	return sorted[0];
}
