import Page from '@components/Layout/Page';
import React from 'react';

const Page404 = () => {
	return (
		<Page title="404">
			<div className="m-w-3xl mx-auto text-center space-y-4">
				<h1 className="font-bold text-5xl">Page Not Found</h1>
				<p className="text-2xl">
					Welcome to the 404 page. Please try a different url or visit the homepage.
				</p>
			</div>
		</Page>
	);
};
export default Page404;
