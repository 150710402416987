import Link from '@components/Link';
import backgroundImage from '@images/better-footer.svg';
import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';
import { IconContext } from 'react-icons';
import {
	FaEnvelope,
	FaFacebook,
	FaInstagramSquare,
	FaLinkedin,
	FaTwitterSquare,
} from 'react-icons/fa';

const icons = {
	facebook: {
		icon: <FaFacebook />,
		path: 'https://www.facebook.com/HackCU/',
	},
	email: {
		icon: <FaEnvelope />,
		path: 'mailto:contact@hackcu.org',
	},
	twitter: {
		icon: <FaTwitterSquare />,
		path: 'https://twitter.com/hackcu',
	},
	instagram: {
		icon: <FaInstagramSquare />,
		path: 'https://www.instagram.com/hackcu',
	},
	linkedin: {
		icon: <FaLinkedin />,
		path: 'https://www.linkedin.com/company/hackcu/',
	},
};

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {}

const Footer: React.FC<FooterProps> = ({ className, ...rest }) => {
	return (
		<footer {...rest} className={clsx('flex flex-col pt-6 pb-2 space-y-3', className)}>
			<div className="mx-auto">For Students By Students</div>

			<div className="mx-auto flex space-x-3">
				<IconContext.Provider value={{ color: 'white', size: '45px' }}>
					{Object.keys(icons).map((icon) => (
						<Link key={icon} outside to={icons[icon].path} aria-label={icon}>
							{icons[icon].icon}
						</Link>
					))}
				</IconContext.Provider>
			</div>

			<Link className="mx-auto text-white font-bold hover:underline" to="/privacy-policy/" outside>
				Privacy Policy
			</Link>

			<img
				src={backgroundImage as string}
				alt="" // TODO: the footer should probably have some sort of alt text
				aria-hidden
				className="w-full"
			/>
		</footer>
	);
};

export default Footer;
